<template>
  <div class="favorite-container">
    <page-top :title="$t('favorite.title')">
      <span style="font-size:26px;" class="fa fa-heart-o"></span>
    </page-top>
    <el-card>
      <section class="form-select">
        <section>
          <el-date-picker
            style="width:250px;margin-right:10px;"
            v-model="dateTime"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('global.select.start')"
            :end-placeholder="$t('global.select.end')"
             :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
          <el-select
            style="width:200px;margin-right:10px;"
            v-model="select"
            :placeholder="$t('global.select.restaurant')"
          >
            <el-option label="label1" value="value1"></el-option>
            <el-option label="label2" value="value2"></el-option>
          </el-select>
          <el-input style="width:200px;" v-model="search" :placeholder="$t('global.select.search')">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button class="ml10 table-btn btn-red">{{$t('global.select.btn2')}}</el-button>
        </section>
        <section>
          <el-button
            @click="addRow('dialogForm')"
            class="table-btn btn-gray"
          >{{$t('global.select.btn3')}}</el-button>
        </section>
      </section>

      <el-table
        :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <el-table-column prop="Id" label="Id" width="60" align="center"></el-table-column>

        <el-table-column
          prop="restaurant"
          :label="$t('favorite.table.col2') "
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column prop="email" :label="$t('favorite.table.col3')" align="center"></el-table-column>
        <el-table-column prop="phone" :label="$t('favorite.table.col5')" align="center"></el-table-column>
        <el-table-column prop="date" :label="$t('favorite.table.col1')" width="180" align="center"></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('favorite.table.col6')"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              @click="editDialog(scope.row)"
              class="btn-green"
              size="mini"
            >{{$t('favorite.table.btn1')}}</el-button>
            <el-button
              @click="deleteDialog = true"
              type="danger"
              size="mini"
            >{{$t('favorite.table.btn2')}}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
      :title="$t('favorite.dialog.title')"
      center
      width="50%"
      :visible.sync="dialogFormVisible"
    >
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form ref="dialogForm" :model="dialogForm" label-width="80px">
            <el-form-item :label="$t('favorite.dialog.form1')">
              <el-input style="width:100%;" v-model="dialogForm.restaurant"></el-input>
            </el-form-item>
            <el-form-item :label="$t('favorite.dialog.form2')">
              <el-input style="width:100%;" v-model="dialogForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('favorite.dialog.form4')">
              <el-input style="width:100%;" v-model="dialogForm.phone"></el-input>
            </el-form-item>
            <el-form-item :label="$t('favorite.dialog.form5')">
              <el-date-picker style="width:100%" v-model="dialogForm.timeRange"></el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <section slot="footer">
        <b-btn class="table-btn btn-yellow">{{$t('favorite.dialog.btn1')}}</b-btn>
        <b-btn class="table-btn btn-gray">{{$t('favorite.dialog.btn2')}}</b-btn>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from '../../components/PageTop/PageTop'
export default {
  data () {
    return {
      select: '',
      dateTime: '',
      search: '',
      tableData: [],
      currentPage: 1,
      pageSize: 10,

      dialogFormVisible: false,
      dialogForm: {
        name: '',
        timeRange: '',
        email: '',
        tel: '',
        phone: ''
      }
    }
  },

  components: {
    pageTop
  },
  created () {
    this.$http.get('/favorite', res => {
      this.tableData = res.data.data;
    })
  },
  methods: {
    toAdd () {
      this.$router.push('/app/addFavorite')
    },
    handleSizeChange (val) {
      this.pageSize = val;
    },
    handleCurrentChange (val) {
      this.currentPage = val;
    },

    addRow (formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = true;
    },


    editDialog (row) {
      this.dialogFormVisible = true;
      Object.assign(this.dialogForm, row)
    }
  }
}

</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>